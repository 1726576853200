import "./bootstrap"
import "../css/app.css"

import { createApp, h } from "vue"
import { createPinia } from "pinia"
import { createInertiaApp } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "../../vendor/tightenco/ziggy/src/js"

import Vue3Toastify from "vue3-toastify"
import options from "@/Plugins/toastify"
import posthogPlugin from "./Plugins/posthog"

const appName = import.meta.env.VITE_APP_NAME || "Laravel"

const pinia = createPinia()

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
  setup({ el, App, props, plugin }) {
    return createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .use(Vue3Toastify, options)
      .use(posthogPlugin)
      .use(pinia)
      .mount(el)
  },
  progress: {
    color: "#4B5563",
  },
})
