import "vue3-toastify/dist/index.css"
import { toast, type ToastContainerOptions } from "vue3-toastify"

export default {
    autoClose: 1500,
    clearOnUrlChange: false,
    containerId: toast.POSITION.BOTTOM_RIGHT,
    limit: 5,
    position: toast.POSITION.TOP_CENTER,
    theme: toast.THEME.COLORED,
    transition: toast.TRANSITIONS.SLIDE,
} as ToastContainerOptions
